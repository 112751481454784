<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="importacao-titulo"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-titulo-importacao-bdgd"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      id="importacao-descricao"
      class="mx-auto"
    >
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-bdgd"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-empresa"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-bdgd"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-mes"
      class="mx-auto"
    >
      <v-col
        cols="12"
        id="fs-mes-dados-importacao-bdgd"
      >
        <input-month
          label="Mês dos dados"
          @monthSelected="monthSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-bdgd-versao"
      class="mx-auto"
    >
      <v-col cols="12">
        <input-bdgd-version
          id="fs-versao-importacao-bdgd"
          label="Versão da BDGD"
          :companyId="companyId"
          :month="mes"
          @bdgdVersionSelected="bdgdVersionSelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="!formatoGdbAneel"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="entidadesParciais"
          label="Importação parcial de entidades?"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="!modulo10Antigo"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="formatoGdbAneel"
          label="Arquivo no formato GDB da ANEEL?"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="!formatoGdbAneel"
    >
      <v-col cols="12">
        <modulo-10-antigo-checkbox
          v-model="modulo10Antigo"
          :companyId="companyId"
          :month="mes"
          :version="+bdgdVersion"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectCompany from '@/components/general/SelectCompany.vue';
import InputMonth from '@/components/general/InputMonth.vue';
import InputBdgdVersion from '@/components/general/InputBdgdVersion.vue';
import Modulo10AntigoCheckbox from '@/components/general/Modulo10AntigoCheckbox.vue';

export default {
  name: 'FirstTabContent',
  components: {
    SelectCompany,
    InputMonth,
    InputBdgdVersion,
    Modulo10AntigoCheckbox
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      companyId: null,
      mes: null,
      bdgdVersion: null,
      modulo10Antigo: false,
      formatoGdbAneel: false,
      entidadesParciais: false
    };
  },
  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    }
  },
  watch: {
    titulo() {
      this.emitEventImportUpdated();
    },
    descricao() {
      this.emitEventImportUpdated();
    },
    companyId() {
      this.emitEventImportUpdated();
    },
    mes() {
      this.emitEventImportUpdated();
    },
    bdgdVersion() {
      this.emitEventImportUpdated();
    },
    formatoGdbAneel() {
      this.emitEventImportUpdated();
    },
    entidadesParciais() {
      this.emitEventImportUpdated();
    },
    modulo10Antigo() {
      this.emitEventImportUpdated();
    }
  },
  methods: {
    companySelected(company) {
      this.companyId = company.id;
    },
    monthSelected(month) {
      this.mes = month;
    },
    bdgdVersionSelected(bdgdVersion) {
      this.bdgdVersion = bdgdVersion;
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        titulo: this.titulo,
        descricao: this.descricao,
        companyId: this.companyId,
        mes: this.mes,
        data: this.data,
        bdgdVersion: this.bdgdVersion,
        formatoGdbAneel: this.formatoGdbAneel,
        entidadesParciais: this.entidadesParciais,
        modulo10_antigo: this.modulo10Antigo
      });
    }
  }
};
</script>
